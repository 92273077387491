<template>
  <div>
    <router-view @updateOverview="updateOverview" />

    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <my-orders-filters v-model="filters" />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.account.myOrders.title') }}</h1>
              <a
                v-if="excel"
                class="main__title__action--right"
                :href="excel"
              >
                <i class="uil uil-cloud-download" />
              </a>

              <span
                v-if="isMobile"
                class="main__title__action--right"
                @click="mobileFiltersOpen = true"
              >
                <i class="uil uil-filter" />
                <Badge v-if="filters.filters.length > 0">{{ filters.filters.length }}</Badge>
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <!-- Loading -->
      <div
        v-if="myOrderInProgress"
        class="row"
      >
        <div class="col">
          <loader />
        </div>
      </div>

      <!-- List -->
      <div
        v-else
        class="row"
      >
        <div class="col-sm-12 col-md-3">
          <my-orders-filters
            v-if="!isMobile"
            v-model="filters"
          />
        </div>
        <div class="col-sm-12 col-md-9">
          <search
            v-model="searchVal"
            class="m-md-t-2"
          />

          <div class="products__overview p-t-2">
            <div v-if="myOrderError">
              <Message
                class="m-t-2"
                type="error"
                :icon="true"
                :content="$t(`api.${myOrderError}`)"
                :show-close="true"
                @closeMessage="closeMessage"
              />
            </div>
            <EmptyState
              v-if="orderResult.length === 0"
              icon="box"
            >
              {{ $t('account.account.myOrders.noOrders') }}
            </EmptyState>
            <router-link
              v-for="(o) in ordersToDisplay"
              :key="o.id"
              :to="{name: 'myOrdersView', params: {id: o.navstatus !== 'pending' ? o.externalorderid : o.id.toString()}}"
              style="    text-decoration: none;"
            >
              <listItemOrder
                :order="o"
                @click.stop
              />
            </router-link>
            <pagination
              class="m-t-4 m-b-4"
              :page="page"
              :pages="pages"
              :items-per-page="itemsPerPage"
              @pageChanged="changePage"
              @itemsPerPageChanged="itemsPerPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import search from '@/components/Search.vue';
import loader from '@/elements/loader.vue';
import EmptyState from '@/elements/EmptyState.vue';
import ListItemOrder from '@/elements/ListItemOrder.vue';
import Message from '@/components/Message.vue';
import pagination from '@/components/Pagination.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import Badge from '@/elements/Badge.vue';

import { GetOrders } from '@/api/api';

const myOrdersFilters = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myorders/filters.vue');

export default {
  name: 'MyOrders',
  metaInfo() {
    return {
      title: this.$t('account.account.myOrders.title'),
    };
  },
  components: {
    search,
    EmptyState,
    loader,
    ListItemOrder,
    Message,
    pagination,
    myOrdersFilters,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Badge,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      allOrders: [],
      orderResult: [],
      myOrderInProgress: true,
      myOrderError: '',
      itemsPerPage: 20,
      page: 1,
      filters: { sort: this.$route.query.sort ? this.$route.query.sort : 'orderdate', filters: [] },
      mobileFiltersOpen: false,
      excel: null,
    };
  },
  computed: {
    ordersToDisplay() {
      return this.orderResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    searchVal: {
      get() {
        return this.$route.query.search;
      },
      set(newVal) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          query: {
            ...this.$route.query,
            search: newVal,
          },
        });
        this.page = 1;
        this.search();
      },
    },
    pages() {
      const pages = [];
      for (
        let i = 0;
        i < this.orderResult.length / this.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
  },
  watch: {
    filters: {
      handler() { this.search(); },
      deep: true,
    },
  },
  async mounted() {
    this.fetchOrders();
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    async updateOverview() {
      await this.fetchOrders();
    },
    async fetchOrders() {
      try {
        const result = await GetOrders(this.$store.state.customer.selectedCustomerId);
        this.allOrders = result.data;
        this.excel = result.excel;

        this.search();
        await this.$store.dispatch('myInvoices/get');
        this.myOrderInProgress = false;
      } catch (err) {
        this.myOrderInProgress = false;
        this.myOrderError = err.name;
        throw Object.assign(err, { status: 500 });
      }
    },
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },

    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    changePage(page) {
      this.page = parseInt(page, 10);
      this.orderResult.slice(
        this.page * this.itemsPerPage - this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    itemsPerPageChanged(itemsPerPage) {
      this.itemsPerPage = parseInt(itemsPerPage, 10);
      this.changePage(1);
    },
    closeMessage() {
      this.$store.dispatch('myorders/clear');
    },
    async search() {
      this.page = 1;
      const filters = {};
      this.filters.filters.forEach((f) => {
        if (!filters[f.type]) {
          filters[f.type] = [];
        }
        filters[f.type].push(f.value);
      });
      if (this.filters.deliveryAddress) {
        filters.deliveryAddress = this.filters.deliveryAddress;
      }

      let allOrders = this.allOrders.filter((o) => {
        if (filters.preorder) {
          if (!filters.preorder.includes(o.preorder)) {
            return false;
          }
        }
        if (filters.isSent) {
          const isSent = o.shippedqty === o.qty;
          if (!filters.isSent.includes(isSent)) {
            return false;
          }
        }
        if (filters.status) {
          const availableStatusses = o.orderlines.map((x) => x.nextstatus);
          if (!filters.status.some((x) => availableStatusses.includes(x))) {
            return false;
          }
        }
        if (filters.deliveryAddress) {
          const address = o.deliveryaddressid;
          if (filters.deliveryAddress !== address) {
            return false;
          }
        }

        return true;
      });

      if (this.$route.query.search) {
        allOrders = await this.$search(this.$route.query.search, allOrders, {
          keys: ['externalorderid', 'reference', 'orderdateFormatted', 'orderlinedescription', 'allserials'],
          defaultAll: true,
          caseSensitive: false,
          shouldSort: true,
          tokenize: false,
          matchAllTokens: true,
          findAllMatches: true,
          threshold: 0.2,
          location: 2,
          distance: 50,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        });
      }

      if (filters.assignable || filters.assigned) {
        allOrders = allOrders.map((x) => {
          const filteredOl = [];
          for (const ol of x.orderlines) {
            const filteredItems = [];
            const olRefs = ol.references || [];
            for (let i = 0; i < ol.qty; ++i) {
              const serial = ol.serials?.[i];
              const index = i + 1;
              const olRef = olRefs.find((r) => (index >= r.index && index < (r.index + r.quantity)) || (serial && r.serial === serial));
              // Assignable ID (just to help with debugging)
              const itemId = {
                externalorderid: x.externalorderid, // Nav order
                orderid: x.externalorderid ? null : x.id, // Unprocessed order
                orderlineid: ol.orderline,
                index,
                serial,
              };

              const assignable = (!olRef || !(olRef.email || olRef.relationId || olRef.firstName || olRef.lastName));
              if ((assignable && filters.assignable) || (!assignable && filters.assigned)) {
                filteredItems.push(itemId);
              }
            }
            if (filteredItems.length > 0) {
              filteredOl.push(filteredItems.length !== ol.qty ? {
                ...ol,
                qty: filteredItems.length,
              } : ol);
            }
          }

          if (filteredOl.length === 0) { return null; }
          return {
            ...x,
            orderlines: filteredOl,
          };
        }).filter((x) => x);
      }

      if (this.filters.sort === 'ETD') {
        allOrders = allOrders.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
          if ((a.shippedqty === a.qty) > (b.shippedqty === b.qty)) {
            return 1;
          } if ((a.shippedqty === a.qty) < (b.shippedqty === b.qty)) {
            return -1;
          }
          if ((a.shippedqty === a.qty)) { // In case we already shipped, then we will sort on orderdate DESCENDING
            if (a.orderdate > b.orderdate) {
              return -1;
            } if (a.orderdate < b.orderdate) {
              return 1;
            }
            return 0;
          } // In case we haven't shipped yet, we will sort on ETD Ascending (soonest arrivals show up first)
          const calcMinETD = (minETD, x) => {
            if (x.shipped !== true && (!minETD || x.promiseddeliverydate < minETD)) {
              minETD = x.promiseddeliverydate;
            }
            return minETD;
          };
          const aMinETD = a.orderlines.reduce(calcMinETD, null);
          const bMinETD = b.orderlines.reduce(calcMinETD, null);
          if (aMinETD < bMinETD) {
            return -1;
          } if (aMinETD > bMinETD) {
            return 1;
          }
          return 0;
        });
      }

      if (this.filters.sort === 'shipmentdate') {
        allOrders = allOrders.sort((a, b) => { // Not perfect, but we will sort on the ETD of the earliest available non-shipped item in case of ETD
          if ((a.shippedqty === a.qty) > (b.shippedqty === b.qty)) {
            return -1;
          } if ((a.shippedqty === a.qty) < (b.shippedqty === b.qty)) {
            return 1;
          }
          if ((a.shippedqty === a.qty)) { // In case we already shipped, then we will sort on orderdate DESCENDING
            const calcMaxShipmentDate = (shipmentDate, x) => {
              if (x.shipped === true && (!shipmentDate || x.shipmentdate > shipmentDate)) {
                shipmentDate = x.shipmentdate;
              }
              return shipmentDate;
            };
            const aMaxShipmentDate = a.orderlines.reduce(calcMaxShipmentDate, null);
            const bMaxShipmentDate = b.orderlines.reduce(calcMaxShipmentDate, null);
            if (aMaxShipmentDate < bMaxShipmentDate) {
              return 1;
            } if (aMaxShipmentDate > bMaxShipmentDate) {
              return -1;
            }
            return 0;
          } // In case we haven't shipped yet, we will sort on ETD Ascending (soonest arrivals show up first)
          if (a.orderdate > b.orderdate) {
            return -1;
          } if (a.orderdate < b.orderdate) {
            return 1;
          }
          return 0;
        });
      }

      this.orderResult = allOrders;
    },
  },

};
</script>
<style scoped>
[v-cloak] > * {
  display: none;
}
[v-cloak]::before {
  content: "loading…";
}
</style>
