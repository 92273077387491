export function canReplaceImage(stock, imgFailed) {
  if (!stock) return false;
  let item = stock.item || stock;
  if (item.externalid && item.externalid.startsWith('SBI') && !imgFailed) {
    // SBIs should all have a valid image
    return false;
  } if (item.image && item.image.startsWith('https://images.cyclingfactory.be/configurator-generated/')
    && item.attributes && item.attributes.groupset && item.attributes.groupset.key !== 'ffs') {
    // Custom bikes that have a generated image should keep that
    return false;
  }
  return true;
}
