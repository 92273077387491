<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.account.myStock.add.title') }}
    </bcfAsideHeader>

    <bcfAsideContent class="content">
      <div v-if="errorMessage">
        <Message
          type="error"
          :icon="true"
          :content="$t(`api.${errorMessage}`)"
        />
      </div>
      <div class="form">
        <SectionTitle>{{ 'Items' }}</SectionTitle>

        <search-input
          :placeholder="$t('account.account.myStock.add.searchOrSerial')"
          :items="searchItems"
          :autofocus="true"
          :loading="searchLoading"
          @search="searchStock($event)"
          @selected="onStockSelected($event)"
        >
          <template v-slot:item="{ item }">
            <OrderedItem
              :item="item"
            />
          </template>
        </search-input>

        <div class="items">
          <Message
            v-if="items.length===0"
            type="error"
            :icon="true"
            :content="$t('account.account.myStock.add.noItemsSelected')"
          />
          <template v-else>
            <div
              v-for="item of items"
              :key="item.serial"
              class="stock-item"
            >
              <OrderedItem
                class="stock-item__detail"
                :item="item"
                :interactive="true"
                :can-replace-image="canReplaceImage"
                @imageinput="onImageInput($event)"
              />

              <button
                class="button--icon button stock-item__remove"
                @click="removeStock(item)"
              >
                <i class="uil uil-times" />
              </button>
            </div>
          </template>
        </div>

        <SectionTitle>{{ 'Availability' }}</SectionTitle>

        <div class="form-group">
          <label>{{ $t('account.account.myStock.status') }}</label>
          <status-select
            v-model="status"
          />
        </div>

        <div
          v-if="locations.length>1"
          class="form-group"
        >
          <label>
            {{ $t('account.account.myStock.address') }}
            <b-dropdown
              variant="secondary"
              size="lg"
              :class="{
                'custom-dropdown': true,
                'locations__dropdown': true
              }"
            >
              <template #button-content>
                <ListItem
                  class="filter-dropdown-content location selected"
                >
                  <div class="location-icon">
                    <i class="uil uil-map-marker-alt" />
                  </div>
                  <div class="location-content">
                    <template v-if="address">
                      <strong>{{ address.Name }}
                      </strong>
                      <small>
                        {{
                          `${address.street}, ${address.postalcode}
                        ${address.city} (${$t(`countries.${address.country}`)})`
                        }}
                      </small>
                    </template>
                    <template v-else>
                      <strong>
                        Select a location
                      </strong>
                    </template>
                  </div>
                </ListItem>

              </template>

              <b-dropdown-item
                v-for="location in locations"
                :key="location.externalid"
                class="location filter-dropdown-item"
                @click="selectLocation(location)"
              >
                <ListItem
                  class="location filter-dropdown-content"
                >
                  <div class="location-icon">
                    <i class="uil uil-map-marker-alt" />
                  </div>
                  <div class="location-content">
                    <strong>{{ location.Name }}
                    </strong>
                    <small>
                      {{
                        `${location.street}, ${location.postalcode}
                            ${location.city} (${$t(`countries.${location.country}`)})`
                      }}
                    </small>
                  </div>
                </ListItem>
              </b-dropdown-item>
            </b-dropdown>
          </label>
        </div>
      </div>

      <div class="controls">
        <button
          class="button button--primary"
          :disabled="!isValid"
          @click="onAdd"
        >
          Add
        </button>
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>
<script>
import { mapState } from 'vuex';
// import loader from '@/elements/loader.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import Message from '@/components/Message.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import ListItem from '@/elements/ListItem.vue';
import {
  AddNewStock, UploadCustomerStockImage, SearchOwnItems,
} from '@/api/api';

import SearchInput from '@/components/searchInput.vue';
import OrderedItem from '@/components/item/orderedItem.vue';
import statusSelect from './statusSelect.vue';
import { canReplaceImage } from './utils';

export default {
  components: {
    bcfAside,
    Message,
    bcfAsideHeader,
    bcfAsideContent,
    SectionTitle,
    // loader,
    SearchInput,
    OrderedItem,
    statusSelect,
    ListItem,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
    addressid: {
      // Defines what we're filtering on
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      errorMessage: '',
      search: '',
      searchItems: [],
      searchLoading: false,
      status: 'available',
      address: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    isValid() {
      return this.items.length && this.status;
    },
  },
  mounted() {
    this.searchStock();
    if (this.addressid) {
      this.address = this.locations.find((l) => l.externalid === this.addressid);
    } else {
      [this.address] = this.locations;
    }
  },
  methods: {
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else {
        this.$router.push(this.$store.state.global.previousRoute ? this.$store.state.global.previousRoute : { name: 'myStock' });
      }
    },
    async searchStock(search) {
      this.searchLoading = true;
      this.search = search;
      try {
        // console.log(`Address id: ${this.addressid}`);
        // console.log(`Search:: ${search}`);
        // console.log(`this.customer.selectedCustomerId: ${this.customer.selectedCustomerId}`);
        // console.log(`user type:${this.user.type}`);
        const searchResult = (await SearchOwnItems(
          this.customer.selectedCustomerId,
          {
            search,
            exclude: ['no-serial', 'stock'], // Don't include items without a serialnumber or if they're already in stock (unless exact match)
          },
        )).filter((x) => !this.items.find((y) => y.serial === x.serial));

        if (this.search === search) {
          // Only store results if it is still the active query
          this.searchItems = searchResult;
          this.searchLoading = false;
        }
      } catch (err) {
        this.searchLoading = false;
        throw err;
      }
    },
    onStockSelected(item) {
      this.items.push(item);
      this.searchItems = this.searchItems.filter((i) => i !== item);
    },
    removeStock(item) {
      this.items = this.items.filter((i) => i !== item);
    },
    async onAdd() {
      const request = {
        items: this.items.map((i) => ({
          serial: i.serial,
          image: i.image,
          externalid: i.externalid,
        })),
        status: this.status,
        address: this.address,
      };
      await AddNewStock(this.customer.selectedCustomerId, request);
      this.$router.push({ name: 'myStock', query: { refresh: true } });
    },
    selectLocation(location) {
      this.address = location;
    },
    updateStock(stock) {
      this.items = this.items.map((i) => (i.serial === stock.serial ? stock : i));
    },
    async onImageInput({ item, file }) {
      // this.imageLoading = true;
      this.$forceUpdate();
      try {
        if (file && file.file) {
          if (file.dataUri) {
            this.updateStock({ ...item, image: file.dataUri, item: { ...item.item, image: file.dataUri } });
          }
          const result = await UploadCustomerStockImage(this.customerId, file.file);
          this.updateStock({ ...item, image: result.url, item: { ...item.item, image: result.url } });
        } else {
          this.updateStock({ ...item, image: null, item: { ...item.item, image: null } });
        }
      } catch (err) {
        console.error(err);
        this.updateStock({
          ...item,
          image: {
            error: this.$t('general.uploadError'),
          },
          item: {
            ...item.item,
            image: {
              error: this.$t('general.uploadError'),
            },
          },
        });
      }
      // this.imageLoading = false;
      this.$forceUpdate();
    },
    canReplaceImage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";

.content{
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .form{
    flex: 1 1 auto;
  }
  .controls{
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .stock-item{
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    &__detail{
      flex: 1 1 auto;
    }
    &__remove{
      flex: 0 0 auto;
      align-self: center;
    }
  }

  .visibleb2c{
    font-size: 2rem;
    display: flex;
  }

  /* Style of location dropdown */
  .locations__dropdown{
    display: flex;
  }
  .filter-dropdown-content {
    border-bottom: none;
    font-size: 13.5px;
    line-height: 15px;
    text-align: left;
    display: inline-block;

    &.location {
      display: inline-flex;
      flex: 1 1 auto;
      overflow: hidden;
    }

    &.selected {
    }

    &:not(.selected) {
      padding: 1.25rem 0;
    }

    .uil {
      margin-right: .5rem;
    }
  }
  @media only screen and (max-width: 450px) {
    &.location {
      max-width: 95vw;
    }
  }

  @media only screen and (min-width: 450px) {
    .filter-dropdown-content {
      min-width: 12.0rem;

      &.location {
        min-width: 30em;
      }
    }
  }

  .filter-dropdown-item {
    &:not(.selected):not(:last-child) {
      border-bottom: 1px solid $light-grey;
    }
  }
  .location-content {
    display: inline-flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;

    small{
      text-overflow: ellipsis;
      overflow: hidden
    }
  }
  .location-icon {
    background-color: $dark-grey;
    border-radius: 2rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 4rem;
    min-height: 4rem;
    color: $white;
    margin-right: 2rem;

    .uil {
      margin: 0;
    }
  }
}
</style>
