<template>
  <div class="products__filter m-md-t-2">
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.options') }}
          </div>
        </template>

        <div class="form-group">
          <select v-model="valueInt.sort">
            <option value="orderdate">
              {{ $t('account.account.myOrders.sort.orderdate') }}
            </option>
            <option value="ETD">
              {{ $t('account.account.myOrders.sort.ETD') }}
            </option>
            <option value="shipmentdate">
              {{ $t('account.account.myOrders.sort.shipmentdate') }}
            </option>
          </select>
        </div>
      </collapse>
    </fieldset>

    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.deliveryAddress') }}
          </div>
        </template>

        <div class="form-group">
          <select v-model="valueInt.deliveryAddress">
            <option
              :value="null"
            >
              {{ $t('general.all') }}
            </option>
            <option
              v-for="location of locations"
              :key="location.externalid"
              :value="location.externalid"
            >
              {{ location.Name }}
            </option>
          </select>
        </div>
      </collapse>
    </fieldset>

    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
              class="section-title section-title--collapse"
              :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.itemReferences') }}
          </div>
        </template>
        <ul>
          <li>
            <checkbox
                v-model="valueInt.filters"
                :checked-value="{ type: 'assignable', value: true }"
            >
              <span>{{ $t('account.account.myOrders.assignable') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
                v-model="valueInt.filters"
                :checked-value="{ type: 'assigned', value: true }"
            >
              <span>{{ $t('account.account.myOrders.assigned') }}</span>
            </checkbox>
          </li>
          <!-- Should we add the opposite as a possible filter? (assigned) -->
        </ul>
      </collapse>
    </fieldset>

    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.orderType') }}
          </div>
        </template>
        <ul>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'preorder', value: true }"
            >
              <span>{{ $t('account.account.myOrders.preorders') }}</span>
            </checkbox>
          </li>
        </ul>
      </collapse>
    </fieldset>
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.orderStatus') }}
          </div>
        </template>
        <ul>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'isSent', value: true }"
            >
              <span>{{ $t('account.account.myOrders.doneStatus.shipped') }}</span>
            </checkbox>
          </li>

          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'isSent', value: false }"
            >
              <span>{{ $t('account.account.myOrders.notShipped') }}</span>
            </checkbox>
          </li>
        </ul>
      </collapse>
    </fieldset>
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('account.account.myOrders.itemstatus') }}
          </div>
        </template>
        <ul>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'processing' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.processing') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'production' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.production') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'stock' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.stock') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'readytoship' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.readytoship') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'shipped' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.shipped') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'paid' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.paid') }}</span>
            </checkbox>
          </li>
          <li>
            <checkbox
              v-model="valueInt.filters"
              :checked-value="{ type: 'status', value: 'done' }"
            >
              <span>{{ $t('account.account.myOrders.pendingStatus.done') }}</span>
            </checkbox>
          </li>
        </ul>
      </collapse>
    </fieldset>
  </div>
</template>
<script>
import collapse from '@/components/collapse.vue';
import checkbox from '@/elements/checkbox.vue';
import { mapState } from 'vuex';

export default {
  components: {
    collapse,
    checkbox,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        sort: 'orderdate',
        deliveryAddress: null,
        filters: [],
      }),
    },
  },
  computed: {
    ...mapState({
      locations: (state) => state.customer.selectedCustomer.addresses.slice().sort((a, b) => (a.Name < b.Name ? -1 : 1)),
      user: (state) => state.auth.user,
    }),
    valueInt: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit('input', newval);
      },
    },
  },
};
</script>
