<template>
  <div>
    <div class="m-t-4 m-b-2 m-md-t-0 m-md-b-4">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <h1>{{ $t("header.account") }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container m-b-2">
      <div class="row">
        <div class="col">
          <div
            class="rep__switcher pointer"
            @click="toggleCustomerChange"
          >
            <div class="rep__image">
              <i class="uil uil-user" />
              <!--<img src="@/assets/img/location_example.jpg" />-->
            </div>
            <div class="rep__account">
              <strong>{{ selectedCustomer.name }}</strong>
              <small>{{ selectedCustomer.externalid }} - {{ username }}</small>
            </div>
            <div class="rep__arrow">
              <i class="uil uil-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container m-b-4">
      <router-view />

      <div class="row">
        <div class="col-md-4 m-t-4">
          <h1>{{ $t('account.account.title') }}</h1>

          <div class="m-t-2">
            <listItem
              v-if="checkCreditLimit && isPreviewMode"
              icon-before
            >
              <router-link to="/account/myBalance">
                <i class="uil uil-coins" />
                {{ $t('account.account.myBalance.title') }}
              </router-link>
            </listItem>
            <listItem
              v-if="isPreviewMode"
              icon-before
            >
              <router-link to="/account/myPoints">
                <i class="uil uil-coins" />
                {{ $t('account.account.myPoints.title') }}
              </router-link>
            </listItem>
            <listItem
              arrow
              icon-before
            >
              <router-link to="/account/stock">
                <i class="uil uil-truck-loading" />
                {{ $t('account.account.myStock.title') }}
              </router-link>
            </listItem>
            <listItem
              icon-before
              arrow
            >
              <router-link to="/account/stats">
                <i class="uil uil-chart" />
                {{ $t('account.account.myStats.title') }}
              </router-link>
            </listItem>
            <listItem
              icon-before
              arrow
            >
              <router-link to="/account/orders">
                <i class="uil uil-box" />
                {{ $t('account.account.myOrders.title') }}
              </router-link>
            </listItem>
            <listItem
              icon-before
              arrow
            >
              <router-link to="/account/customers">
                <i class="uil uil-users-alt" />
                {{ $t('account.account.myCustomers.title') }}
              </router-link>
            </listItem>
            <listItem
              icon-before
              arrow
            >
              <router-link to="/account/invoices">
                <i class="uil uil-invoice" />
                {{ $t('account.account.myInvoices.title') }}
              </router-link>
            </listItem>
            <listItem
              icon-before
              arrow
            >
              <router-link to="/account/warranties">
                <i class="uil uil-parcel" />
                {{ $t('account.account.myWarranties.title') }}
              </router-link>
            </listItem>
            <listItem icon-before>
              <router-link to="/account/contracts">
                <i class="uil uil-file-edit-alt" />
                {{ $t('account.account.myContract.title') }}
              </router-link>
            </listItem>
            <listItem
              v-if="isPreviewMode"
              icon-before
            >
              <i class="uil uil-pricetag-alt" />
              {{ $t('account.account.myDiscounts.title') }}
            </listItem>
            <listItem
              icon-before
            >
              <router-link to="/account/exports">
                <i class="uil uil-cloud-download" />
                {{ $t('account.account.myExports.title') }}
              </router-link>
            </listItem>
          </div>
        </div>

        <div class="col-md-4 m-t-4">
          <h1>{{ $t('account.about.title') }}</h1>

          <div class="m-t-2">
            <listItem
              v-if="userType >= 2"
              icon-before
              arrow
            >
              <i class="uil uil-table" />
              <router-link to="/account/targets">
                {{ $t('account.about.targets.title') }}
              </router-link>
            </listItem>
            <listItem
              icon-before
              arrow
            >
              <i class="uil uil-life-ring" />
              <router-link to="/account/support">
                {{ $t('account.about.helpAndSupport.title') }}
              </router-link>
            </listItem>

            <listItem
              icon-before
              arrow
            >
              <i class="uil uil-scenery" />
              <router-link to="/account/promo">
                {{ $t('account.about.promo.title') }}
              </router-link>
            </listItem>

            <listItem
              icon-before
              arrow
            >
              <i class="uil uil-rss" />
              <router-link to="/account/newsAndUpdates">
                {{ $t('account.about.newsAndUpdates.title') }}
              </router-link>
            </listItem>

            <listItem
              icon-before
              arrow
            >
              <i class="uil uil-facebook-f" />
              <router-link to="/account/socialFeeds">
                {{ $t('account.about.socialFeed.title') }}
              </router-link>
            </listItem>

            <listItem
              icon-before
            >
              <i class="uil uil-ship" />
              <router-link to="/account/releases">
                {{ $t('account.about.releaseNotes.title') }}
              </router-link>
            </listItem>
          </div>
        </div>
        <div class="col-md-4 m-t-4">
          <h1>{{ $t('account.settings.title') }}</h1>

          <div class="m-t-2">
            <listItem toggle-before>
              <toggle v-model="showRetailPrices">
                {{ $t('account.account.showRetailPrices.title') }}
              </toggle>
            </listItem>
            <ListItem icon-before>
              <i class="uil uil-user" />
              <router-link to="/account/edit">
                {{ $t('account.settings.edit.title') }}
              </router-link>
            </ListItem>
            <ListItem icon-before>
              <i class="uil uil-cog" />
              <router-link
                to="/account/companyDetails"
              >
                {{ $t('account.settings.companyDetails.title') }}
              </router-link>
            </ListItem>
            <ListItem
              icon-before
              arrow
            >
              <i class="uil uil-store" />
              <router-link to="/account/locations">
                {{ $t('account.settings.myLocations.title') }}
              </router-link>
            </ListItem>
            <ListItem
              v-if="userType === 5"
              icon-before
              arrow
            >
              <i
                class="
              uil
              uil-brackets-curly"
              />
              <router-link to="/account/vouchers">
                {{ $t('account.settings.vouchers.title') }}
              </router-link>
            </ListItem>
            <ListItem
              icon-before
            >
              <i class="uil uil-exchange" />
              <router-link
                to="/account/apiCustomerKey"
              >
                {{ $t('account.settings.api.url') }}
              </router-link>
            </ListItem>
            <ListItem
              v-if="isPreviewMode"
              icon-before
            >
              <i class="uil uil-lock-open-alt" />
              <router-link
                to="/account/changePassword"
              >
                {{ $t('account.settings.changePassword.title') }}
              </router-link>
            </ListItem>
            <ListItem icon-before>
              <i class="uil uil-bell" />
              <a
                class="pointer"
                @click="showNotificationsSettings = true"
              >
                {{ $t('account.settings.notifications.title') }}
              </a>
            </ListItem>
            <ListItem icon-before>
              <i class="uil uil-sign-out-alt" />
              <a
                href="#"
                @click="logout()"
              >{{ $t('header.logout') }}</a>
            </ListItem>
          </div>
        </div>
      </div>
    </div>
    <accountChange
      v-if="changeCustomer"
      :close="toggleCustomerChange"
    />
    <notificationsSettings
      v-if="showNotificationsSettings"
      @close="showNotificationsSettings = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ListItem from '@/elements/ListItem.vue';
import toggle from '@/elements/toggle.vue';
import accountChange from '@/views/account/settings/change.vue';
import notificationsSettings from '@/views/account/settings/notifications.vue';

export default {
  components: {
    ListItem, toggle, accountChange, notificationsSettings,
  },
  metaInfo() {
    return {
      title: this.$t('header.account'),
    };
  },
  data() {
    return {
      changeCustomer: false,
      showNotificationsSettings: false,
    };
  },
  computed: {
    checkCreditLimit() {
      return this.selectedCustomer.attributes.CreditLimitLCY > 0;
    },
    ...mapState({
      username: (state) => state.auth.user.username,
      selectedCustomer: (state) => state.customer.selectedCustomer,
      isPreviewMode: (state) => state.global.isPreviewMode,
      user: (state) => state.auth.user,
      userType: (state) => state.auth.user.type,
    }),
    showRetailPrices: {
      get() {
        return this.$store.state.items.showRetailPrices;
      },
      set(newVal) {
        this.$store.commit('items/showRetailPrices', newVal);
      },
    },
  },
  mounted() {
    /* if (this.selectedCustomer.externalid === 'CUS0038') {
      this.creditlimit = 265000;
    } */
  },
  methods: {
    toggleCustomerChange() {
      this.changeCustomer = !this.changeCustomer;
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>
</style>
