<template>
  <bcf-aside @close="close">
    <form
      style="display: contents"
      @submit.prevent="sendOrderUpdate"
    >
      <bcfAsideHeader
        v-if="loaded && !errorMessage"
        :close="close"
      >
        {{ $t('account.account.myOrders.view.title')+ ': ' + loadOrderId }}
      </bcfAsideHeader>

      <bcfAsideContent>
        <div v-if="!loaded">
          <loader class="m-b-2" />
        </div>
        <div v-if="errorMessage">
          <Message
            type="error"
            :icon="true"
            :content="$t(`api.${errorMessage}`)"
          />
        </div>
        <div v-if="loaded && !errorMessage">
          <Message
            v-if="order.preorder"
            type="update"
            :icon="false"
          >
            {{ $t('account.account.myOrders.preorder') }}
          </Message>

          <table class="table table--striped m-b-4">
            <tr>
              <td>
                <strong>{{ $t('account.account.myOrders.orderdate') }}:</strong>
              </td>
              <td>{{ customFormatter(order.orderdate) }}</td>
            </tr>

            <tr>
              <td>
                <strong>{{ $t('account.account.myOrders.price') }}:</strong>
              </td>
              <td>
                <price
                  :price="order.total"
                  :currency="order.currency"
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('account.account.myOrders.reference') }}:</strong>
              </td>
              <td v-if="canEditReferences">
                <input
                  v-model="orderUpdate.reference"
                  style="width: 100%"
                  type="text"
                  maxlength="35"
                >
              </td>
              <td v-else>
                {{ order.reference }}
              </td>
            </tr>
          </table>
          <Message
            v-if="orderUpdated"
            type="success"
            :icon="false"
            :show-close="false"
          >
            Saved successfully!
          </Message>
          <!--
        <Message
          v-if="checkValue && !buttonClicked"
          type="error"
          :icon="false"
          :show-close="false"
        >
          Do not forget to update your changes.
        </Message> -->
          <SectionTitle>{{ $t('account.account.myOrders.view.status') }}</SectionTitle>
          <div
            v-for="(ol, index) in order.orderlines"
            :key="index"
            @click="toggleDetails(index)"
          >
            <Message
              v-if="ol.preorder"
              type="update"
              :icon="false"
            >
              {{ $t('account.account.myOrders.preorderdetail') }}
            </Message>
            <div class="order-item-detail">
              <header>
                <div
                  class="order-item-detail__image"
                >
                  <img
                    :src="ol.image ? ol.image : `https://images.cyclingfactory.be/${ol.externalid}_image_400x.jpg`"
                    :alt="ol.externalid"
                    @error="imgFailed"
                    @click="openLightboxImage(ol, $event)"
                  >
                  <span class="order-item-detail__amount">
                    <Badge type="amount">{{ ol.qty }}</Badge>
                  </span>
                </div>
                <div class="order-item-detail__content">
                  <div class="d-flex">
                    <small
                      v-if="!(ol.orderstatus.details.find(x => x.status==='shipped')
                        && ol.orderstatus.details.find(x => x.status==='shipped').date !== null)"
                      style="color: #8d99af;"
                    >
                      <span v-if="ol.promiseddeliverydate">
                        <strong>ETD</strong>: {{ customFormatter(ol.promiseddeliverydate) }}
                      </span>
                      <span v-else-if="ol.requesteddeliverydate">
                        <strong>RTD</strong>: {{ customFormatter(ol.requesteddeliverydate) }}
                      </span>
                    </small>
                    <div
                      class="order-item-detail__references"
                      @click.stop="editOrderlineReferences(ol)"
                    >
                      <div class="order-item-detail__references__list">
                        <template
                          v-for="item in (ol.references||[])"
                        >
                          <span
                            v-if="(item.email||item.note||item.firstName||item.lastName)"
                            :key="`${item.orderline}_${item.index||item.serial}`"
                            class="order-item-detail__references__item"
                            :class="{ 'order-item-detail__references__item__updated': order.referencesUpdateAvailable }"
                          >
                            {{ [item.firstName,item.lastName].filter(x=>x).join(' ') || item.email || item.note }}
                          </span>
                        </template>
                      </div>
                      <div class="order-item-detail__references__icon">
                        <img
                          src="@/assets/img/references.png"
                          style="width:17px;height:17px;"
                        >
                        <!-- <i class="uil uil-edit" /> -->
                      </div>
                    </div>
                    <!-- ol.externalid.substring(0,3) !== 'ECB' && -->
                    <div
                      v-if="ol.customizerAvailable && ol.canswitchdesign && !(ol.newconfigurationid && !ol.designchange) || (ol.newconfigurationid && ol.designdenied)"
                      title="Change design"
                      class="order-item-detail__designchange"
                      @click.stop="editDesign(ol)"
                    >
                      <div class="order-item-detail__designchange__icon">
                        <img
                          src="@/assets/img/configurator-colorwheel.67356ca.png"
                          style="width:15px;height:15px;"
                        >
                        <!-- <i class="uil uil-cog" /> -->
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="(ol.newconfigurationid && !ol.designchange && !ol.designdenied)"
                    class="d-flex"
                  >
                    <img
                      src="@/assets/img/pending-clock.png"
                      style="width:18px;height:18px; margin-right:2px"
                    >
                    <small style="margin-bottom:10px;">
                      <!-- Request pending for design change(id: {{ ol.newconfigurationid }})! -->
                      {{ $t('account.account.myOrders.view.designpending', {config: ol.newconfigurationid}) }}
                    </small>
                  </div>
                  <div
                    v-if="(ol.newconfigurationid && ol.designdenied)"
                    class="d-flex"
                  >
                    <img
                      src="@/assets/img/cancel.png"
                      style="width:18px;height:18px; margin-right:2px"
                    >
                    <small>
                      {{ $t('account.account.myOrders.view.designdenied', {config: ol.newconfigurationid}) }}
                    </small>
                  </div>
                  <div
                    v-if="(ol.newconfigurationid && ol.designchange)"
                    class="d-flex"
                  >
                    <img
                      src="@/assets/img/checkmark.png"
                      style="width:18px;height:18px; margin-right:2px"
                    >
                    <small style="margin-bottom:10px;">
                      {{ $t('account.account.myOrders.view.designaccepted', {config: ol.newconfigurationid}) }}
                    </small>
                  </div>
                  <strong>{{ ol.description }}</strong>
                  <div>
                    <span
                      v-for="serial in ol.serial"
                      :key="serial"
                      class="tag tag__serial"
                    >
                      <i
                        class="uil uil-pricetag-alt"
                        style="margin: -0.5em;"
                      /> {{ serial }}
                    </span>
                  </div>

                  <small>
                    <i
                      v-if="(ol.orderstatus.details.find(x => x.status==='shipped')
                        && ol.orderstatus.details.find(x => x.status==='shipped').date !== null)"
                      v-b-tooltip.hover
                      class="uil uil-truck m-r-1"
                      :title="'Shipped '+customFormatter(ol.orderstatus.details.find(x => x.status==='shipped').date)"
                    />
                    {{ $t('account.account.myOrders.pendingStatus.'+ nextOrderStatus(ol.orderstatus)) }}
                  </small>
                </div>
                <div class="order-item-detail__date">
                  <b-dropdown
                    right
                    no-caret
                  >
                    <!-- TODO : translate -->
                    <b-dropdown-item @click.stop="toggleDetails(index)">
                      Order details
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="ol.configurationid"
                      @click.stop="openConfiguration(ol.configurationid)"
                    >
                      {{ $t('account.account.myOrders.configuration') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="ol.itemid">
                      <router-link
                        :to="{name: 'itemdetail', params: {itemid: ol.itemid}}"
                        style="text-decoration: none; color: black"
                      >
                        Productinfo
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="serial in ol.serial"
                      :key="serial"
                      @click.stop="createWarranty(serial)"
                    >
                      Create warranty for {{ serial }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </header>

              <div
                v-show="openDetails.includes(index)"
                class="order-item-detail__status"
              >
                <div
                  v-for="(s,i) in ol.orderstatus.details"
                  :key="i"
                  class="order-item-detail__status__row"
                >
                  <div
                    v-if="s.date"
                    class="order-item-detail__status__content"
                  >
                    <span class="status--done">
                      <i class="uil uil-check" />
                    </span>
                    <strong>{{ $t('account.account.myOrders.doneStatus.'+ s.status) }}</strong>
                  </div>
                  <div
                    v-else
                    class="order-item-detail__status__content"
                  >
                    <span class="status--pending">
                      <i
                        v-if="s.status === nextOrderStatus(ol.orderstatus)"
                        class="uil uil-clock"
                      />
                    </span>
                    {{
                      s.status === nextOrderStatus(ol.orderstatus)
                        ? $t('account.account.myOrders.pendingStatus.'+ s.status)
                        : $t('account.account.myOrders.nextStatus.'+ s.status)
                    }}
                  </div>
                  <div class="order-item-detail__date">
                    <span v-if="s.date">{{ customFormatter(s.date) }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="invoices.length > 0"
            class="m-b-2"
          >
            <SectionTitle class="m-b-0">
              {{ $t('account.account.myOrders.view.invoices') }}
            </SectionTitle>
            <div
              v-for="(invoice, i) in invoices"
              :key="i"
              @click="openInvoice(invoice)"
            >
              <list-item-invoice
                :invoice="invoice"
              />
            </div>
          </div>
          <div
            v-if="shipments.length > 0"
            class="m-b-2"
          >
            <SectionTitle class="m-b-0">
              {{ $t('account.account.myOrders.view.shipments') }}
            </SectionTitle>
            <div
              v-for="shipment in order.trackingCodes"
              :key="shipment"
            >
              <ListItem
                icon-before
                arrow
              >
                <i class="uil uil-truck" />
                <a
                  v-if="shipment.shipmentcode"
                  :href="shipment.$$pdf"
                  target="_blank"
                >{{ shipment.shipmentcode }}</a>
                <a
                  :href="`${getTrackingLink(shipment)}`"
                  target="_blank"
                >{{ shipment.agent + ': ' + shipment.trackingcode }}</a>
              </ListItem>
            </div>
          </div>

          <div v-if="!checkId($route.params.id) && order.$$pdf">
            <SectionTitle class="m-b-0">
              {{ $t('account.account.myOrders.action') }}
            </SectionTitle>
            <ListItem
              v-if="order.$$pdf"
              icon-before
            >
              <i class="uil uil-file-download-alt" />
              <a
                :href="order.$$pdf"
                target="_blank"
              >{{ $t('account.account.myOrders.download') }}</a>
            </ListItem>
            <ListItem
              v-for="trackingCode in order.trackingCodes.filter(x => x.$$pdf)"
              :key="trackingCode.shipmentcode"
              icon-before
            >
              <i class="uil uil-file-download-alt" />
              <a
                :href="trackingCode.$$pdf"
                target="_blank"
              >{{ $t('account.account.myOrders.downloadShipment') + ': ' + trackingCode.shipmentcode }}</a>
            </ListItem>
          </div>
        </div>
      </bcfAsideContent>
      <bcfAsideFooter
        v-if="hasOrderUpdate"
        style="justify-content: flex-end"
      >
        <button
          class="button button--primary"
          :class="{'button--loading': inProgress}"
          :disabled="inProgress"
          type="submit"
          @click.prevent="sendOrderUpdate"
        >
          Update
        </button>
      </bcfAsideFooter>
    </form>
    <myInvoicesView
      v-if="invoiceOpen"
      :id="invoiceOpen"
      :use-close-event="true"
      @close="openInvoice()"
    />
    <newWarranty
      v-if="warrantyOpen"
      :serial="warrantyOpen"
      :use-close-event="true"
      @close="createWarranty()"
    />
    <configuration-view
      v-if="configurationOpen"
      :code="configurationOpen"
      :use-close-event="true"
      @close="openConfiguration()"
    />
    <LightBox
      ref="lightbox"
      :images="lightboxImage"
      :show-caption="true"
      :show-light-box="false"
    />
  </bcf-aside>
</template>
<script>
import { mapState } from 'vuex';
import loader from '@/elements/loader.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import ListItem from '@/elements/ListItem.vue';
import ListItemInvoice from '@/elements/ListItemInvoice.vue';
import Badge from '@/elements/Badge.vue';
import price from '@/elements/Price.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import Message from '@/components/Message.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import LightBox from '@/components/LightBox/LightBox.vue';
import { nextOrderStatus } from '@/utils/orderUtils';
import { getOrderDetail, updateNavOrderReference } from '@/api/api';
import { floatValue, formatPrice } from '@/utils/pricingUtils';
import { formatDate } from '@/utils/dateUtils';
import validWarranty from '@/utils/warrantyUtils';
import configurationView from '@/views/customizer/configurationView.vue';
import OrderlineReference from '@/views/account/account/myorders/orderlineReference.vue';
import OrderlineDesign from '@/views/account/account/myorders/orderlineDesign.vue';
import { isEqual } from '@/utils/object';
import dialogs from '@/utils/dialogs';

const myInvoicesView = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/myinvoices/view.vue');
const newWarranty = () => import(/* webpackChunkName: "myAccount" */ '@/views/account/account/mywarranties/new.vue');

export default {
  components: {
    Badge,
    price,
    bcfAside,
    Message,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    SectionTitle,
    ListItem,
    ListItemInvoice,
    loader,
    myInvoicesView,
    newWarranty,
    configurationView,
    LightBox,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      order: {},
      loaded: false,
      errorMessage: '',
      shipments: [],
      invoices: [],
      notValid: false,
      isWarranty: false,
      openDetails: [],
      invoiceOpen: null,
      warrantyOpen: null,
      configurationOpen: null,
      lightboxImage: [],
      orderUpdate: {
        reference: undefined,
      },
      editingOrderline: null,
      orderUpdated: false,
      updateError: null,
      inProgress: false,
      linenr: null,
      previousroute: null,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasOrderUpdate) {
      const confirmed = await dialogs.show({
        title: this.$t('general.unsavedChanges.title'),
        content: this.$t('general.unsavedChanges.content'),
        type: 'cancelYes',
      });
      if (confirmed) {
        next();
      } else {
        next(false);
      }
    } else {
      if (this.orderUpdated) this.$emit('updateOverview');
      next();
    }
  },
  computed: {
    ...mapState({
      dhlUrl: (state) => state.language.currentDhl,
      jclUrl: (state) => state.language.currentJcl,
    }),
    loadOrderId() {
      let result = null;
      if (this.order.externalorderid) {
        result = this.order.externalorderid;
      } else {
        result = this.$route.params.id;
      }
      return result;
    },
    origOrderUpdate() {
      // Extract editable fields into its own object for easy comparison if something changed
      const { order } = this;
      if (!order) return {};
      return {
        reference: order.reference,
      };
    },
    hasOrderUpdate() {
      const orig = this.origOrderUpdate;
      const changed = this.orderUpdate;
      return !isEqual(orig, changed);
    },
    canEditReferences() {
      return !(this.invoices.length > 0);
    },
  },
  async mounted() {
    // console.log(this.$route.params.id);
    this.previousroute = this.$store.state.global.previousRoute;
    await this.fetchData();
    this.linenr = this.$route.query.linenr;
    if (this.linenr) {
      const orderline = this.order.orderlines.find((o) => parseInt(o.orderline, 10) === parseInt(this.linenr, 10));
      this.editDesign(orderline);
    }
  },
  methods: {
    resetOrderUpdate() {
      this.orderUpdate = JSON.parse(JSON.stringify(this.origOrderUpdate));
    },
    async editOrderlineReferences(ol) {
      const { order } = this;
      const result = await dialogs.show({
        component: OrderlineReference,
        props: {
          orderline: ol,
          order,
          value: ol.references,
        },
      });
      if (result) {
        await this.fetchData();
      }
    },
    async editDesign(ol) {
      const result = await dialogs.show({
        component: OrderlineDesign,
        props: {
          o: {
            orderid: this.order.id,
            externalorderid: this.order.externalorderid,
            externalcustomerid: this.order.externalcustomerid,
            customerid: this.order.customerid,
            orderline: ol,
            newconfigid: this.$route.query.c,
          },
        },
      });
      if (result) {
        await this.fetchData();
      }
    },
    async sendOrderUpdate() {
      this.inProgress = true;
      this.updateError = null;
      try {
        const { orderUpdate } = this;
        const result = await updateNavOrderReference(this.$route.params.id, orderUpdate.reference);
        await this.fetchData();
        this.orderUpdated = result;
        this.resetOrderUpdate();
      } catch (err) {
        this.updateError = err;
      } finally {
        this.inProgress = false;
      }
    },
    toggleDetails(index) {
      if (this.openDetails.includes(index)) {
        this.openDetails = this.openDetails.filter((o) => o !== index);
      } else {
        this.openDetails.push(index);
      }
    },
    async createWarranty(serial) {
      this.warrantyOpen = serial;
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else if (this.previousroute !== null) {
        if (this.previousroute.includes('configurator')) {
          this.$router.push({ name: 'myOrders' });
        } else {
          this.$router.push(this.previousroute);
        }
      } else {
        this.$router.push({ name: 'myOrders' });
      }
    },
    formatPrice,
    floatValue,
    validWarranty,
    getInvoiceNumbers(arInvoice) {
      let strInvoices = '';
      let counter = 0;
      arInvoice.forEach((inv) => {
        if (counter > 0) {
          strInvoices += '/';
        }
        counter += 1;
        strInvoices += inv;
      });

      return strInvoices;
    },
    customFormatter(date) {
      return (date) ? formatDate(date) : '-';
    },
    checkId(value) {
      return value.search(/so/i) === -1;
    },
    async fetchData() {
      try {
        const result = await getOrderDetail(
          this.$store.state.customer.selectedCustomer.id,
          this.id,
        );
        this.order = result.result;
        if (this.order.orderlines) {
          this.order.orderlines.forEach((ol) => {
            ol.show = false;
            ol.orderstatus.details = ol.orderstatus.details.sort((a, b) => {
              if (a.order > b.order) {
                return 1;
              }
              if (b.order > a.order) {
                return -1;
              }
              return 0;
            });
          });
          this.shipments = [...new Set(this.order.orderlines.reduce((shipments, ol) => {
            if (ol.orderstatus && ol.orderstatus.details) {
              const shipmentStatus = ol.orderstatus.details.find((o) => o.status === 'shipped');
              if (shipmentStatus && shipmentStatus.trackingcodes) {
                return [...shipments, ...shipmentStatus.trackingcodes];
              }
            }
            return shipments;
          }, []))];

          this.invoices = [...new Set(this.order.orderlines.reduce((invoicenumbers, ol) => {
            if (ol.invoicenumbers) {
              return [...invoicenumbers, ...ol.invoicenumbers];
            }
            return invoicenumbers;
          }, []))];
        }
        this.resetOrderUpdate();
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        this.errorMessage = error.message;
      }
    },
    nextOrderStatus,
    openInvoice(invoice) {
      this.invoiceOpen = invoice;
    },
    openConfiguration(code) {
      this.configurationOpen = code;
    },
    openLightboxImage(ol, event) {
      if (!event.target.failed) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();

        const image = {
          thumb: ol.image ? ol.image : `https://images.cyclingfactory.be/${ol.externalid}_image_400x.jpg`,
          src: ol.image ? ol.image : `https://images.cyclingfactory.be/${ol.externalid}_image_2048x.jpg`,
          caption: ol.description,
        };
        this.lightboxImage = [{
          ...image,
          key: 'product',
        }];
        this.$refs.lightbox.showImage(0);
      }
    },
    getTrackingLink(shipment) {
      switch (shipment.agent) {
        case 'DHL': return this.dhlUrl + shipment.trackingcode;
        case 'JCL': return this.jclUrl + shipment.trackingcode;
        default: return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.olddate {
  text-decoration: line-through;
  font-size: 1.5rem;
  margin-right: 1rem;
  opacity: 0.5;
  margin: 0;
}
label {
  font-size: 1.5rem;
  color: #576681;
  display: block;
  margin-top: 1rem;
}
.tag__serial{
  margin-bottom: 3px;
  display: inline-block;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height:1.5rem;
  font-size:1.4rem;
  font-family: monospace;
  letter-spacing: 0.07em;
  background: black;
  padding: 0.5rem 0.5rem 0.5rem 1.2rem;
}
</style>
